<template>
  <div>
    <v-dialog
      :value="openAddAttribute"
      width="auto"
      @input="openAddAttribute = false"
    >
      <v-card width="300">
        <v-card-text class="d-flex align-center pt-2">
          <v-text-field
            v-model="newAttribute"
            dense
            hide-details
          />
          <v-btn
            v-if="addAttribute"
            text
            outlined
            small
            @click="addAttribute"
          >
            Ajouter
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="d-flex align-center">
      <v-autocomplete
        v-model="attributes"
        :label="inputLabel($t('views.products.forms.input.' + kind))"
        :items="items"
        hide-details
        clearable
        dense
        outlined
        deletable-chips
        multiple
        item-text="name"
        item-value="id"
      >
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            small
            close
            @click="data.select"
            @click:close="removeAttribute(data.item)"
          >
            <div :class="{custom: data.item.custom}">
              {{ data.item.localized_name.value }}
              <LocalizedTextInfo
                v-if="data.item.localized_name"
                :translation-key="data.item.localized_name.translation_key"
                :translations="data.item.translations"
              />
              <template v-else>
                {{ data.item.name }}
              </template>
            </div>
          </v-chip>
        </template>
      </v-autocomplete>
      <v-btn
        icon
        small
        class="ml-1"
        @click="openAddAttribute = true"
      >
        <v-icon
          small
          color="blue lighten-1"
        >
          mdi-plus-circle
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import inputMixin from '@/mixins/input'
import LocalizedTextInfo from '@/components/core/LocalizedTextInfo.vue'
import { mapState } from 'vuex'

export default {
  components: { LocalizedTextInfo },
  mixins: [ inputMixin ],
  props: {
    value: {
      type: Array,
      required: false,
      default: null,
    },
    kind: {
      type: String,
      required: false,
      default: null,
    },
    required: Boolean,
    taxonomyAttributes: {
      type: Array,
      default: null,
    },
  },
  data () {
    return {
      openAddAttribute: false,
      newAttribute: '',
      additionalAttributes: [],
    }
  },
  computed: {
    ...mapState(['taxonomies']),
    filteredTaxonomies () {
      if (!Array.isArray(this.taxonomies[this.kind])) {
        return null
      }

      return this.taxonomies[this.kind]
        ?.filter(t => !this.taxonomyAttributes || this.taxonomyAttributes.includes(t.attribute.id)) ?? []
    },
    attributes: {
      get () { return this.value
        ?.filter(attribute =>
          attribute.kind === this.kind &&
          (
            !attribute.taxonomy_id ||
            this.filteredTaxonomies?.map(t => t.id).includes(attribute.taxonomy_id)
          )
        )
        ?.map(attribute => attribute.taxonomy_id ?? attribute.name ) ?? []
      },
      set (value) {
        const attributes = [
          ... this.value.filter((v) => v.kind !== this.kind),
          ...value.map(v => {
            const taxonomy = this.filteredTaxonomies?.find(elt => elt.id === v) || null
            if (taxonomy !== null) {
              return {
                name: taxonomy.name,
                kind: taxonomy.kind,
                taxonomy_id: taxonomy.id,
              }
            }

            return {
              name: v,
              kind: this.kind,
            }
          }),
        ]

        this.$emit('input', attributes)
      },
    },
    items () {
      return [
        ...this.filteredTaxonomies?.map(item => ({ id: item.id, name: item.name })) ?? [],
        ...(
            this.value?.map(item => (!item.taxonomy_id && item.kind === this.kind
              ? {
                id: item.name,
                name: item.name,
                custom: true,
                localized_name: item.localized_name,
                translations: item.translations,
              }
              : null)
            ) ?? [])
          .filter(v => v),
        ...this.additionalAttributes.map(a => ({ id: a, name: a, custom: true })),
      ]
    },
  },
  watch: {
    taxonomyAttributes (value) {
      if (null === value) {
        return
      }

      this.$emit(
        'input', this.value.filter(attribute => (
          !attribute.taxonomy_id ||
          !this.filteredTaxonomies ||
          this.filteredTaxonomies.map(t => t.id).includes(attribute.taxonomy_id)
        ))
      )
    },
  },
  methods: {
    addAttribute() {
      this.additionalAttributes = [...this.additionalAttributes, this.newAttribute]
      this.attributes = [...this.attributes, this.newAttribute]
      this.newAttribute = null
      this.openAddAttribute = false
    },
    removeAttribute (item) {
      this.$emit(
        'input',
        this.value.filter((v) => {

          if (item.custom) {
            return v.taxonomy_id !== null || v.name !== item.id
          }
          if (!item.custom) {
            return v.taxonomy_id !== item.id
          }
        })
      )
    },
  },
}
</script>

<style scoped>
 .custom {
   font-style: italic;
 }
</style>
